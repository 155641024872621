import { api } from '../axios'
import { objToFormData } from '../utils/formData'
import { toQueryString } from '../utils/utils'

/**
 * List all call bridges with pagination and filters.
 * @param {number} [page=1] - The page number.
 * @param {number} [limit=10] - The number of items per page.
 * @param {Object} filters - The filters to apply.
 * @returns {Promise<Object>} The response data.
 */
const listAllCallBridges = async (filters, page = 1, limit = 1) => {
  const filtersString = toQueryString(filters)
  const res = await api.get(
    `conference-bridges?page=${page}&limit=${limit}&${filtersString}`
  )
  return res.data
}

/**
 * Create a new bridges.
 * @param {Object} data - The bridges data.
 * @returns {Promise<Object>} The response data.
 */
const createBridge = async data => {
  const res = await api.post('conference-bridges', data)
  return res.data
}

/**
 * Get a single bridges by ID.
 * @param {number} id - The bridges ID.
 * @returns {Promise<Object>} The response data.
 */
const getOneBridge = async id => {
  const res = await api.get(`conference-bridges/${id}`)
  return res.data
}

/**
 * Uploads a bridges sound file to the server.
 *
 * @param {File} file - The sound file to be uploaded.
 * @returns {Promise<Object>} The response data from the server.
 */
const uploadBridgeSound = async file => {
  if (!file) return
  const formData = objToFormData({ file })
  const res = await api.post(`bridge-sounds/upload`, formData)
  return res.data
}

/**
 * Create a new bridge call.
 * @param {Object} data - The bridge call data.
 * @returns {Promise<Object>} The response data.
 */
const createBridgeCall = async data => {
  const res = await api.post('bridge-calls', data)
  return res.data
}

/**
 * List all bridge calls
 * @param {number} [page=1] - The page number.
 * @param {number} [limit=10] - The number of items per page.
 * @param {Object} filters - The filters to apply.
 * @returns {Promise<Object>} The response data.
 */
const listAllBridgeCalls = async (filters, page = 1, limit = 10) => {
  const filtersString = toQueryString(filters)
  const res = await api.get(
    `bridge-calls?page=${page}&limit=${limit}&${filtersString}`
  )
  return res.data.data
}

/**
 * Delete a bridge call by ID.
 * @param {string} id - The bridge call ID.
 * @returns {Promise<Object>} The response data.
 */
const deleteBridgeCall = async id => {
  const res = await api.delete(`bridge-calls/${id}`)
  return res.data
}

const CallBridgesService = {
  listAllCallBridges,
  createBridge,
  getOneBridge,
  uploadBridgeSound,
  createBridgeCall,
  listAllBridgeCalls,
  deleteBridgeCall
}

export default CallBridgesService
