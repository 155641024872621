<template>
  <div class="page" id="call-bridge-list">
    <div class="mb-2 p-3 card header">
      <h2 class="m-0">
        {{ $t('call-bridge.list.title') }}
      </h2>
      <router-link to="/call-bridge/create" class="px-4 py-3 btn btn-primary">
        {{ $t('call-bridge.list.create') }}
      </router-link>
    </div>

    <div class="content">
      <data-table
        ref="bridges"
        :data="bridges"
        :tableStyle="{
          'min-height': '400px'
        }"
        :fields="translatedTableFields"
        :loading="isLoading"
        :pagination="pagination"
        @changePage="onPageChange"
      >
        <template v-slot:actions="{ row }">
          <router-link
            :to="`/call-bridge/${row.id}/bridge-details`"
            class="btn btn-primary p-2"
          >
            <i class="fa fa-eye"></i>
          </router-link>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataTable from '../../../elements/Table.vue'
import CallBridgesService from '../../../services/callBridge.service'

let interval

export default {
  components: {
    DataTable
  },
  data() {
    return {
      filters: {},
      bridges: [],
      isLoading: false,
      pagination: {
        totalPages: 0,
        page: 1
      },
      bridgeId: null
    }
  },
  computed: {
    ...mapGetters(['currentTenant']),
    dir() {
      return this.$i18n.locale() === 'ar' ? 'rtl' : 'ltr'
    },
    translatedTableFields() {
      return [
        {
          accessor: 'name',
          header: this.$t('call-bridge.list.table.name')
        },
        {
          accessor: 'actions',
          header: this.$t('call-bridge.list.table.actions'),
          width: '40%'
        }
      ]
    }
  },
  methods: {
    onIsActiveChange(value) {
      this.filters.isActive = value
      this.pagination.page = 1
      this.$router.push({ query: { page: 1 } })
      this.fetchCallBridges({ loading: true })
    },
    onPageChange(page) {
      this.pagination.page = page
      this.fetchCallBridges({ loading: true })
      this.$router.push({ query: { page } })
    },
    async fetchCallBridges({ loading = true }) {
      try {
        if (loading) this.isLoading = true
        // fetch call bridges
        const filters = {
          ...this.filters,
          isActive: this.filters.isActive === 'active'
        }
        const res = await CallBridgesService.listAllCallBridges(
          filters,
          this.pagination.page,
          10
        )
        const { flows, ...pagination } = res.data
        this.bridges = flows
        this.pagination = {
          ...this.pagination,
          ...pagination
        }
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message

        console.log(errorMessage)
        this.toast(errorMessage, {
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  mounted() {
    this.pagination.page = this.$route.query.page || 1
    this.fetchCallBridges({ loading: true })
    interval = setInterval(() => {
      this.fetchCallBridges({ loading: false })
    }, 10000)
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(interval)
    next()
  },
  watch: {
    currentTenant() {
      this.fetchCallBridges({ loading: true })
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotating {
  animation: spin 1s linear infinite;
}

.header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
</style>
